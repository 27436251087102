import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Gallery from "../components/vendor-details/gallery"
import ListingDetails from "../components/vendor-details/ListingDetails"
import { SEO } from "../components/seo"

const vendorDetail = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;
  return (
    <Layout bgWhite={true}>
      {/* <div className="main-container">
                <div>{frontmatter.title}</div>
                <img src={frontmatter.social_image} />
            </div> */}
      <Gallery data={frontmatter.photos} slug={fields.slug} />
      <ListingDetails data={frontmatter} slug={fields.slug} />
    </Layout>
  )
};

export default vendorDetail

export const Head = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <SEO title={frontmatter.title} description={frontmatter.description} />
  )
}

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      frontmatter {
        title
        services
        description
        photos {
          title
          image
        }
        logoInfo {
          name
          subHeading
          logo
        }
        aboutVendor {
          since
          description
        }
        amenities
        videoId
        pricing {
          charges
          paymentPolicy
          cancellationPolicy
        }
        contactPerson {
          name
          image
          whatsNumber
          contactNumber
        }
        contacts {
          mapId
          site
          emails
          contacts
          address
          shortenAddress
        }
        social {
          facebook
          youtube
          instagram
          twitter
          linkedin
          pinterest
        }
        workingHours{
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
      }
    }
  }
`;