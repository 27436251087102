import tw, { styled } from 'twin.macro';

const StyledPricing = styled.div`
    .wrapper {
        ${tw`bg-white rounded p-6 shadow-md`}
        h2 {
            ${tw`text-2xl font-semibold relative inline-flex`}
    
            &:after {
                ${tw`absolute [content: ''] left-0 -bottom-1 h-0.5 w-full bg-primary`}
            }
        }

        ul {
            ${tw`flex flex-col gap-4 pt-8`}

            li {
                ${tw`flex flex-col gap-2`}

                .heading {
                    ${tw`uppercase text-gray-700 font-medium`}
                }

                .content {
                    ${tw`text-gray-800`}
                }
            }
        }
    }
`;
export default StyledPricing