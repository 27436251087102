import tw, { styled } from 'twin.macro';

const StyledLocationContacts = styled.div`
${tw`bg-white rounded shadow-md`}
    h2 {
        ${tw`text-2xl font-semibold relative inline-flex mx-6 mt-6 `}

        &:after {
            ${tw`absolute [content: ''] left-0 -bottom-1 h-0.5 w-full bg-primary`}
        }
    }

    .map-wrapper {
        ${tw`pt-8`}

        iframe {
            ${tw`w-full`}
        }
    }

    ul.details {
        ${tw`mx-6 mt-8 mb-6 flex flex-col gap-6`}

        li {
            ${tw`flex flex-col gap-4`}

            .wrapper {
                ${tw`flex flex-row gap-4`}

                .icon-wrapper {
                    ${tw`w-12 h-12 text-dark bg-gray-100 rounded flex shrink-0 items-center justify-center`}
    
                    .icon {
                        ${tw`text-2xl`}
                    }
                }

                .details {
                    h5 {
                        ${tw`text-primary font-medium`}
                    }
                    a {
                        ${tw`font-medium text-sm`}
                    }
                }
            }
    
            hr {
                ${tw`h-0.5 bg-amber-50 w-full mt-2`}
            }
        }
    }

    ul.social {
        ${tw`border-[1px] border-b-amber-50 px-6 py-8 flex flex-row md:gap-6 gap-4 justify-center items-center`}

        li {
            a {
                ${tw`w-10 h-10 flex items-center justify-center rounded-full hover:opacity-90`}

                .icon {
                    ${tw`text-xl text-white`}
                }
                &.facebook {
                    ${tw`bg-[#3B5998]`}
                }
                &.youtube {
                    ${tw`bg-[#C4302B]`}
                }
                &.instagram {
                    ${tw`bg-[#3F729B]`}
                }
                &.twitter {
                    ${tw`bg-[#5AB1F1]`}
                }
                &.linkedin {
                    ${tw`bg-[#0072B1]`}
                }
                &.pinterest {
                    ${tw`bg-[#C8232C]`}
                }
            }
        }
    }
`;
export default StyledLocationContacts