import React from "react"
import StyledContactPerson from "./style/contact-person";
import { BsWhatsapp } from "react-icons/bs"
import { FiPhoneCall } from "react-icons/fi"
import ReactWhatsapp from "react-whatsapp";

const ContactPerson = ({ data, slug }) => {
    const imgPath = process.env.GATSBY_IMAGE_URL
    const imgURL = `${imgPath}${slug.toLowerCase()}${data.image}`
    return (
        <StyledContactPerson>
            <h2>
                Contact Person
            </h2>
            <div className="contact-widget">
                <div className="left">
                    {data.image && <img src={imgURL} alt="" />}
                    <h3>{data.name}</h3>
                </div>
                <div className="right">
                    <ReactWhatsapp number={data.whatsNumber} className="icon-wrapper">
                        <BsWhatsapp className="icon" />
                    </ReactWhatsapp>
                    <a href={`tel:${data.contactNumber}`} className="icon-wrapper phone">
                        <FiPhoneCall className="icon" />
                    </a>
                </div>
            </div>
        </StyledContactPerson>
    )
};

export default ContactPerson
