import tw, { styled } from 'twin.macro';

const StyledVideo = styled.div`
    .wrapper {
        ${tw`bg-white rounded p-6 shadow-md`}
        h2 {
            ${tw`text-2xl font-semibold relative inline-flex`}
    
            &:after {
                ${tw`absolute [content: ''] left-0 -bottom-1 h-0.5 w-full bg-primary`}
            }
        }

        .video-wrapper {
            ${tw`pt-8 w-full`}

            .vjs_video_3-dimensions {
                ${tw`w-full`}
            }
        }
    }
`;
export default StyledVideo