import React, { useState } from "react"
import StyledVendorContactForm from "./style/vendor-contact-form"
import { useForm } from "react-hook-form"
import { contactForm } from "../../services/api"
import thankyouImg from "../../assets/images/thankyou-mail.png"
import { BiMessageRounded } from 'react-icons/bi';

const VendorContactForm = ({ title, toEmail, range, vendorName }) => {
    const [showModal, setShowModal] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        trigger,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        data.whatsapp = enabled ? "Yes" : "No";
        data.range = range;
        data.toEmail = toEmail;
        data.createdDate = new Date().toLocaleString() + '';
        data.vendorName = vendorName;
        setIsLoading(true)
        contactForm(data)
            .then(res => {
                if (res.status === 201) {
                    setSubmitted(true)
                    setIsLoading(false)
                }
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
            })
    };
    return (
        <StyledVendorContactForm>
            <button
                className="cta"
                type="button"
                onClick={() => {
                    setShowModal(!showModal);
                }}
            >
                <BiMessageRounded className="icon" />
                Send Message
            </button>
            {showModal ? (
                <>
                    <div
                        className="main-wrapper"
                    >
                        <div className="overlay"
                            onClick={() => {
                                setShowModal(!showModal);
                            }}
                        >
                        </div>
                        <div className="main-container">
                            {/*content*/}
                            <div className="content">
                                {/*header*/}
                                <div className="header-wrapper">
                                    <div>
                                        <p>
                                            {title}
                                        </p>
                                        {!submitted && <h1>Send Message</h1>}
                                    </div>
                                    <button
                                        onClick={() => {
                                            setShowModal(!showModal);
                                        }}
                                    >
                                        <span className="close">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                {submitted && !isLoading ?
                                    <div className="body">
                                        <div className="submission-success">
                                            <img src={thankyouImg} alt="" />
                                            <h3>Thank You</h3>
                                            <p>Your message has been recieved.</p>
                                            <p>We will be in touch and contact you soon!</p>
                                            <button
                                                onClick={() => {
                                                    setShowModal(!showModal);
                                                }}
                                            >
                                                Back to site
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="body">
                                            <h3>
                                                <span>{title} </span>will get in touch with you as soon as you fill this form. All given information will be kept private and secure.
                                            </h3>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                {/* Name input */}
                                                <div className="wrapper">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder=" "
                                                        className={`${errors.name && 'error'}`}
                                                        {...register("name", {
                                                            required: "Name is required",
                                                            pattern: {
                                                                value: /^[a-zA-Z ]+$/,
                                                                message: "Please enter valid name",
                                                            },
                                                        })}
                                                        onKeyUp={() => {
                                                            trigger("name")
                                                        }}
                                                    />
                                                    <label htmlFor="name">Full Name</label>
                                                    {errors.name && (
                                                        <small className="text-red" id="Name_error">
                                                            {errors.name.message}
                                                        </small>
                                                    )}
                                                </div>

                                                {/* Email input */}
                                                <div className="wrapper">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder=" "
                                                        className={`${errors.email && 'error'}`}
                                                        {...register("email", {
                                                            required: "Email is required",
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "Please enter valid email address",
                                                            },
                                                        })}
                                                        onKeyUp={() => {
                                                            trigger("email")
                                                        }}
                                                    />
                                                    <label htmlFor="email">Email Address</label>
                                                    {errors.email && (
                                                        <small className="text-red" id="Email_error">
                                                            {errors.email.message}
                                                        </small>
                                                    )}
                                                </div>

                                                {/* Mobile input */}
                                                <div className="wrapper">
                                                    <input
                                                        type="tel"
                                                        name="phone"
                                                        placeholder=" "
                                                        className={`${errors.phone && 'error'}`}
                                                        {...register("phone", {
                                                            required: "Phone Number is required",
                                                            pattern: {
                                                                value: /^\d*(?:\.\d{1,2})?$/,
                                                                message: "Please enter valid Phone Number",
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: "Please enter 10 Digit Phone Number",
                                                            },
                                                            minLength: {
                                                                value: 10,

                                                                message: "Please enter  10 Digit Phone Number",
                                                            },
                                                        })}
                                                        onKeyUp={() => {
                                                            trigger("phone")
                                                        }}
                                                    />
                                                    <label htmlFor="phone">Mobille Number</label>
                                                    {errors.phone && (
                                                        <small className="text-red" id="Phone_error">
                                                            {errors.phone.message}
                                                        </small>
                                                    )}
                                                </div>

                                                {/* Date */}
                                                <div className="wrapper">
                                                    <input
                                                        type="date"
                                                        name="date"
                                                        placeholder="date"
                                                        min={new Date().toISOString().split('T')[0]}
                                                        className={`${errors.date && 'error'}`}
                                                        {...register("date", {
                                                            required: "Date is required",
                                                            pattern: {
                                                                value: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                                                                message: "Date must be a valid date in the format YYYY-MM-DD",
                                                            },
                                                        })}
                                                        onKeyUp={() => {
                                                            trigger("date")
                                                        }}
                                                    />
                                                    <label htmlFor="date">Date</label>
                                                    {errors.date && (
                                                        <small className="text-red" id="Date_error">
                                                            {errors.date.message}
                                                        </small>
                                                    )}
                                                </div>

                                                {/* About */}
                                                <div className="wrapper about">
                                                    <textarea
                                                        name="about"
                                                        placeholder=" "
                                                        defaultValue={`Hi ${title}, We would like to know more about your services and any packages you offer. We look forward to your reply!`}
                                                        className={`${errors.about && 'error'}`}
                                                        {...register("about", {
                                                            required: "About Event is required",
                                                        })}
                                                        onKeyUp={() => {
                                                            trigger("about")
                                                        }}
                                                    />
                                                    <label htmlFor="about">About Event</label>
                                                    {errors.about && (
                                                        <small className="text-red" id="About_error">
                                                            {errors.about.message}
                                                        </small>
                                                    )}
                                                </div>

                                                {/* Whats App */}
                                                <div className="whatsapp">
                                                    <p>Notify me on Whatsapp</p>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            className="sr-only peer"
                                                            checked={enabled}
                                                            readOnly
                                                        />
                                                        <div
                                                            onClick={() => {
                                                                setEnabled(!enabled);
                                                            }}
                                                            className="toggle bg-gray-200 peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-600"
                                                        ></div>
                                                    </div>
                                                </div>

                                                {/* CTA */}
                                                <div className="submit">
                                                    <button type="submit" disabled={isLoading}>
                                                        Send Message
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        {
                                            isLoading &&
                                            <div className="tsl-preloader">
                                                <div className='loading-spinner'>
                                                    <div className="preloader-spin-1"></div>
                                                    <div className="preloader-spin-2"></div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </StyledVendorContactForm>
    )
};

export default VendorContactForm
