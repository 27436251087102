import tw, { styled } from 'twin.macro';

const StyledVendorInfo = styled.div`
    .wrapper {
        ${tw`bg-white rounded p-6 flex md:flex-row flex-col justify-between md:items-center items-start gap-4 shadow-md`}
        .listing-heading {
            ${tw`text-3xl font-semibold`}
        }
    
        .tag {
            ${tw`text-base`}
        }
    
        .address {
            ${tw`flex flex-row items-center gap-1 text-sm font-medium`}
            .image {
                ${tw`md:w-[250px] w-[100px]`}
            }
        }
    }
`;
export default StyledVendorInfo