import React, { useState } from "react"
import StyledGallery from "./style/gallery"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LightBox, { Modal, ModalGateway } from "react-images";
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const Gallery = ({ data, slug }) => {
    const courseList = [];
    const imgPath = process.env.GATSBY_IMAGE_URL
    data.forEach(edge => {
        courseList.push({
            download_url: edge.image,
            source: `${imgPath}${slug.toLowerCase()}gallery/${edge.image}`,
            caption: edge.title,
            alt: edge.title,
        })
    });
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({});
    const toggleLightbox = (post, selectedIndex) => {
        setLightboxIsOpen(!lightboxIsOpen);
        setSelectedImage({ index: selectedIndex });
    };
    return (
        <StyledGallery>
            <>
                <Carousel
                    additionalTransfrom={0}
                    showDots={false}
                    arrows={true}
                    autoPlaySpeed={3000}
                    autoPlay={false}
                    className="slider"
                    containerClass="container-with-dots"
                    dotListClass="dots"
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass="carousel-top"
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside
                    responsive={responsive}
                    centerMode={true}
                >
                    {courseList.map((post, indx) => {
                        return (
                            <div
                                key={indx}
                                onClick={() => toggleLightbox(post, indx)}
                            >
                                <img src={post.source} />
                            </div>
                        );
                    })}
                </Carousel>
                <ModalGateway>
                    {lightboxIsOpen ? (
                        <Modal onClose={toggleLightbox}>
                            <LightBox
                                currentIndex={selectedImage.index}
                                views={courseList}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </>
        </StyledGallery>
    )
};

export default Gallery