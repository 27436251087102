import tw, { styled } from 'twin.macro';

const StyledGallery = styled.section`
${tw`md:pt-28 pt-14`};
.slider {
    img {
        ${tw`md:h-[500px] h-[400px] object-cover w-full`}
    }
}
`;

export default StyledGallery;