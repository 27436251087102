import React from "react"
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import StyledFaqs from "./style/faqs";
const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                {header}
            </>
        }

    />
);
const Faqs = ({ data }) => {
    return (
        <StyledFaqs>
            <div className="wrapper">
                <h2>Frequently Asked Questions</h2>
                <Accordion transition transitionTimeout={200}>
                    {data.map(({ header, answer }, i) => (
                        <AccordionItem
                            key={i}
                            header={header}
                            itemKey={`Item-${i + 1}`}
                            initialEntered={i === 0 ? true : false}
                        >
                            {answer}
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
        </StyledFaqs>
    )
};

export default Faqs
