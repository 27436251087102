import React from "react"
import StyledPricing from "./style/pricing";

const Pricing = ({ data }) => {
    return (
        <StyledPricing>
            <div className="wrapper">
                <h2>Pricing</h2>
                <ul>
                    <li>
                        <span className="heading">Charges Per Event</span>
                        <span className="content">{data.charges}</span>
                    </li>
                    {data.paymentPolicy &&
                        <li>
                            <span className="heading">Payment Policy</span>
                            <span className="content" dangerouslySetInnerHTML={{ __html: data.paymentPolicy }} />
                        </li>
                    }
                    {data.cancellationPolicy &&
                        <li>
                            <span className="heading">Cancellation Policy</span>
                            <span className="content">
                                {data.cancellationPolicy}
                            </span>
                        </li>
                    }
                </ul>
            </div>
        </StyledPricing>
    )
};

export default Pricing
