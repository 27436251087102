import tw, { styled } from 'twin.macro';

const StyledListingDetails = styled.section`
${tw`md:py-12 py-4 bg-amber-100/40`};
.main-container {
    ${tw`flex md:(px-8 flex-row) flex-col container mx-auto py-5 gap-8 bg-transparent text-black`};
    .left-wrapper {
      ${tw`md:w-2/3 w-full flex flex-col gap-6`}
    }
    .right-wrapper {
      ${tw`md:w-1/3 w-full flex flex-col gap-6`}
    }
}
`;
export default StyledListingDetails