import React from "react"
import StyledLocationContacts from "./style/location-contacts";
import { BsGlobe, BsTelephone } from "react-icons/bs"
import { FiMapPin } from "react-icons/fi"
import { MdOutlineEmail } from "react-icons/md"
import { TfiYoutube } from "react-icons/tfi"
import { FaInstagram, FaTwitter, FaLinkedinIn, FaPinterestP, FaFacebookF } from "react-icons/fa"

const LocationContacts = ({ data, social }) => {
    return (
        <StyledLocationContacts>
            <h2>Location/Contacts</h2>
            <div className="map-wrapper">
                <iframe src={data.mapId}
                    height="300" style={{ border: 0 }} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <ul className="details">
                {data.site &&
                    <li>
                        <div className="wrapper">
                            <div className="icon-wrapper">
                                <BsGlobe className="icon" />
                            </div>
                            <div className="details">
                                <h5>Live Site</h5>
                                <a href={data.site} target="_blank" rel="noreferrer">{data.site}</a>
                            </div>
                        </div>
                        <hr />
                    </li>
                }
                <li>
                    <div className="wrapper">
                        <div className="icon-wrapper">
                            <MdOutlineEmail className="icon" />
                        </div>
                        <div className="details">
                            <h5>Mail Us</h5>
                            {data.emails.map((item, index) => (
                                <a href="mailto:contact@housefullevents.in" key={index}>{index > 0 && ", "}{item}</a>
                            ))}
                        </div>
                    </div>
                    <hr />
                </li>
                <li>
                    <div className="wrapper">
                        <div className="icon-wrapper">
                            <BsTelephone className="icon" />
                        </div>
                        <div className="details">
                            <h5>Call Us</h5>
                            {data.contacts.map((item, index) => (
                                <a href={`tel:${item}`} key={index}>{index > 0 && ", "}{item}</a>
                            ))}
                        </div>
                    </div>
                    <hr />
                </li>
                <li>
                    <div className="wrapper">
                        <div className="icon-wrapper">
                            <FiMapPin className="icon" />
                        </div>
                        <div className="details">
                            <h5>Address</h5>
                            <a href={data.shortenAddress} target="_blank" rel="noreferrer">
                                {data.address}
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
            {social &&
                <ul className="social">
                    {social.facebook &&
                        <li>
                            <a className="facebook" href={social.facebook} target="_blank" rel="noreferrer">
                                <FaFacebookF className="icon" />
                            </a>
                        </li>
                    }
                    {social.youtube &&
                        <li>
                            <a className="youtube" href={social.youtube} target="_blank" rel="noreferrer">
                                <TfiYoutube className="icon" />
                            </a>
                        </li>
                    }
                    {social.instagram &&
                        <li>
                            <a className="instagram" href={social.instagram} target="_blank" rel="noreferrer">
                                <FaInstagram className="icon" />
                            </a>
                        </li>
                    }
                    {social.twitter &&
                        <li>
                            <a className="twitter" href={social.twitter} target="_blank" rel="noreferrer">
                                <FaTwitter className="icon" />
                            </a>
                        </li>
                    }
                    {social.linkedin &&
                        <li>
                            <a className="linkedin" href={social.linkedin} target="_blank" rel="noreferrer">
                                <FaLinkedinIn className="icon" />
                            </a>
                        </li>
                    }
                    {social.pinterest &&
                        <li>
                            <a className="pinterest" href={social.pinterest} target="_blank" rel="noreferrer">
                                <FaPinterestP className="icon" />
                            </a>
                        </li>
                    }
                </ul>
            }
        </StyledLocationContacts>
    )
};

export default LocationContacts
