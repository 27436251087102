import React from "react"
import StyledAboutVendor from "./style/about-vendor";
import { TbFlag3 } from "react-icons/tb"
const AboutVendor = ({ data }) => {
    return (
        <StyledAboutVendor>
            <div className="heading">
                <h2>
                    About
                </h2>
                <div className="exp">
                    <TbFlag3 className="icon" />
                    <span>Working Since : {data.since} </span>
                </div>
            </div>
            <div className="content" dangerouslySetInnerHTML={{ __html: data.description }} />
        </StyledAboutVendor>
    )
};

export default AboutVendor
