import tw, { styled } from 'twin.macro';

const StyledWorkingHours = styled.div`
    .wrapper {
        ${tw`bg-white rounded p-6 shadow-md`}
        h2 {
            ${tw`text-2xl font-semibold relative inline-flex`}

            &:after {
                ${tw`absolute [content: ''] left-0 -bottom-1 h-0.5 w-full bg-primary`}
            }
        }
        ul {
            ${tw`flex flex-col pt-6`}

            li {
                ${tw`py-4 flex flex-row justify-between border-b-[1px] last:border-b-0 border-b-gray-200`}
            }
        }
    }
`;
export default StyledWorkingHours