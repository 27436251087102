import React from "react"
import StyledAmenities from "./style/amenities";
import { AiFillCheckCircle } from "react-icons/ai"

const Amenities = ({ data }) => {
    return (
        <StyledAmenities>
            <h2>
                Amenities
            </h2>
            <ul>
                {data.map((list, index) => (
                    <li key={index}><AiFillCheckCircle className="icon" /><span>{list}</span></li>
                ))}
            </ul>
        </StyledAmenities>
    )
};

export default Amenities
