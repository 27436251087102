import tw, { styled } from 'twin.macro';

const StyledContactPerson = styled.div`
${tw`bg-white rounded p-6 shadow-md`}
    h2 {
        ${tw`text-2xl font-semibold relative inline-flex`}

        &:after {
            ${tw`absolute [content: ''] left-0 -bottom-1 h-0.5 w-full bg-primary`}
        }
    }

    .contact-widget {
        ${tw`flex flex-row justify-between pt-8 items-center`}

        .left {
            ${tw`flex flex-row gap-4 items-center`}
            img {
                ${tw`rounded-full`}
            }
            h3 {
                ${tw`font-medium max-w-[140px] [word-wrap: break-word]`}
            }
        }

        .right {
            ${tw`flex items-center justify-center relative gap-4`}

            .icon-wrapper {
                ${tw`w-9 h-9 rounded-full bg-[#25D366] z-[1] flex items-center justify-center`}
                .icon {
                    ${tw`text-white text-base`}
                }

                &.phone {
                    ${tw`bg-primary`}
                }
            }

            &:before {
                ${tw`[content: ""] absolute bg-[#f9f9f9] rounded-l-xl -top-3 -bottom-3 -right-6 -left-5 border border-[#eee] border-r-0`}
            }
        }
    }
`;
export default StyledContactPerson