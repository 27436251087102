import tw, { styled } from 'twin.macro';

const StyledFaqs = styled.div`
    .wrapper {
        ${tw`bg-white rounded p-6 shadow-md`}
        h2 {
            ${tw`text-2xl font-semibold relative inline-flex`}
    
            &:after {
                ${tw`absolute [content: ''] left-0 -bottom-1 h-0.5 w-full bg-primary`}
            }
        }
        .szh-accordion {
            ${tw`flex flex-col gap-6 pt-8`}
            .szh-accordion__item {
                ${tw`border border-gray-200 bg-white rounded p-4 flex flex-col gap-6`}

                h3 {
                    ${tw`font-bold`}

                    button {
                        ${tw`w-full text-left relative`}

                        &:before, &:after {
                            ${tw`[content: ''] bg-black absolute block transition-all`}
                        }
                        &:before {
                            ${tw`w-3.5 h-0.5 [top: calc(50% - 1px)] right-4`}
                        }
                        &:after {
                            ${tw`w-0.5 h-3.5 [top: calc(50% - 7px)] right-[calc(1rem + 6px)]`}
                        }
                    }
                }

                &.szh-accordion__item--expanded {
                    button {
                        ${tw`w-full text-left relative`}
                        &:before {
                            ${tw`rotate-180`}
                        }
                        &:after {
                            ${tw`rotate-90 translate-x-0.5`}
                        }
                    }
                }
            }
        }
    }
`;
export default StyledFaqs    