import React from "react"
import StyledVideo from "./style/video";
import ReactPlayer from 'react-player'
const Video = ({ data }) => {
    return (
        <StyledVideo>
            <div className="wrapper">
                <h2>
                    Portfolio Video
                </h2>
                <div className="video-wrapper">
                    <ReactPlayer
                        className='react-player'
                        url={`https://www.youtube.com/watch?v=${data}`}
                        width='100%'
                        height='400px'
                    />
                </div>
            </div>
        </StyledVideo>
    )
};

export default Video
