import tw, { styled } from 'twin.macro';

const StyledAmenities = styled.div`
${tw`bg-white rounded p-6 shadow-md`}
    h2 {
        ${tw`text-2xl font-semibold relative inline-flex`}

        &:after {
            ${tw`absolute [content: ''] left-0 -bottom-1 h-0.5 w-full bg-primary`}
        }
    }
    ul {
        ${tw`grid md:grid-cols-3 grid-cols-1 gap-x-4 gap-y-6 items-center pt-8`}
        li {
            ${tw`flex flex-row gap-2`}
            .icon {
                ${tw`text-primary w-6 h-6 shrink-0`}
            }
            span {
                ${tw`font-medium`}
            }
        }
    }
`;

export default StyledAmenities;