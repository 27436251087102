import React from "react"
import StyledWorkingHours from "./style/working-hours";

const WorkingHours = ({ data }) => {
    return (
        <StyledWorkingHours>
            <div className="wrapper">
                <h2>Working Hours</h2>
                <ul>
                    <li>
                        <div className="week">Monday</div>
                        <div className="week">{data.monday}</div>
                    </li>
                    <li>
                        <div className="week">Tuesday</div>
                        <div className="week">{data.tuesday}</div>
                    </li>
                    <li>
                        <div className="week">Wednesday</div>
                        <div className="week">{data.wednesday}</div>
                    </li>
                    <li>
                        <div className="week">Thursday</div>
                        <div className="week">{data.thursday}</div>
                    </li>
                    <li>
                        <div className="week">Friday</div>
                        <div className="week">{data.friday}</div>
                    </li>
                    <li>
                        <div className="week">Saturday</div>
                        <div className="week">{data.saturday}</div>
                    </li>
                    <li>
                        <div className="week">Sunday</div>
                        <div className="week">{data.sunday}</div>
                    </li>
                </ul>
            </div>
        </StyledWorkingHours>
    )
};

export default WorkingHours
