import tw, { styled } from 'twin.macro';

const StyledAboutVendor = styled.div`
    ${tw`bg-white rounded p-6 shadow-md`}

    .heading {
        ${tw`flex flex-row justify-between mb-8`}
        h2 {
            ${tw`text-2xl font-semibold relative inline-flex`}
    
            &:after {
                ${tw`absolute [content: ''] left-0 -bottom-1 h-0.5 w-full bg-primary`}
            }
        }

        .exp {
            ${tw`flex flex-row items-center gap-1 text-sm font-medium`}

            .icon {
                ${tw`text-primary w-6 h-6`}
            }
        }
    }

    .content {
        p {
            ${tw`pb-2 last:p-0 text-gray-500`}
        }
    }
`;
export default StyledAboutVendor    
