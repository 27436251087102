import React from "react"
import StyledListingDetails from "./style/listing-details";
import VendorInfo from "./VendorInfo";
import AboutVendor from "./AboutVendor";
import Amenities from "./Amenities";
import Video from "./Video";
import Pricing from "./Pricing";
import Faqs from "./Faqs";
import ContactPerson from "./ContactPerson";
import LocationContacts from "./LocationContacts";
import WorkingHours from "./WorkingHours";
import VendorContactForm from "../common/VendorContactForm";

const ListingDetails = ({ data, slug }) => {
    return (
        <StyledListingDetails>
            <div className="main-container">
                <div className="left-wrapper">
                    <VendorInfo data={data.logoInfo} slug={slug} />
                    <AboutVendor data={data.aboutVendor} />
                    <Amenities data={data.amenities} />
                    {data.videoId &&
                        <Video data={data.videoId} />
                    }
                    {data.pricing &&
                        <Pricing data={data.pricing} />
                    }
                    {data.faqs && data.faqs[0].heading &&
                        <Faqs data={data.faqs} />
                    }
                </div>
                <div className="right-wrapper">
                    <ContactPerson data={data.contactPerson} slug={slug} />
                    <VendorContactForm title={data.title} toEmail={data.contacts.emails[0]} range={data.services[0]} vendorName={data.logoInfo.name} />
                    <LocationContacts data={data.contacts} social={data.social} />
                    <WorkingHours data={data.workingHours} />
                </div>
            </div>
        </StyledListingDetails>
    )
};

export default ListingDetails
