import React from "react"
import StyledVendorInfo from "./style/vendor-info";

const VendorInfo = ({ data, slug }) => {
    const imgPath = process.env.GATSBY_IMAGE_URL
    const imgURL = `${imgPath}${slug.toLowerCase()}${data.logo}`
    return (
        <StyledVendorInfo>
            <div className="wrapper">
                <div>
                    <h2 className="listing-heading">
                        {data.name}
                    </h2>
                    <span className="tag">
                        {data.subHeading}
                    </span>
                </div>
                <span className="address">
                    <img src={imgURL} className="image" alt="" />
                </span>
            </div>
        </StyledVendorInfo>
    )
};

export default VendorInfo
